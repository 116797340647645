import enUS from '../constants/nationalities/nationalities-en_US.json'
import frFR from '../constants/nationalities/nationalities-fr_FR.json'

export const useNationalities = () => {
  const nationalitiesMap = {
    fr: Object.keys(frFR).map((code) => ({
      value: code,
      text: frFR[code].text,
      priorite: frFR[code].priorite,
    })),
    en: Object.keys(enUS).map((code) => ({
      value: code,
      text: enUS[code].text,
      priorite: enUS[code].priorite,
    })),
  }

  const getAllNationalities = (locale) => {
    return nationalitiesMap[locale]
  }

  const MONEGASQUE = (locale) =>
    nationalitiesMap[locale].find(
      (nationality) => nationality.text === 'Monégasque',
    ).value

  return {
    getAllNationalities,
    MONEGASQUE,
  }
}
