export const formatStreet = (
  streetAddress,
  firstAdditionalAddress,
  secondAdditionalAddress,
) => {
  let concatenatedStreet = streetAddress
  if (firstAdditionalAddress === '' && secondAdditionalAddress !== '') {
    firstAdditionalAddress = secondAdditionalAddress
    secondAdditionalAddress = ''
  }

  if (firstAdditionalAddress && firstAdditionalAddress !== '') {
    concatenatedStreet = concatenatedStreet
      .concat('\n')
      .concat(firstAdditionalAddress)
  }
  if (secondAdditionalAddress && secondAdditionalAddress !== '') {
    concatenatedStreet = concatenatedStreet
      .concat('\n')
      .concat(secondAdditionalAddress)
  }
  return concatenatedStreet
}
